@import './fonts.css';
@import url('https://fonts.googleapis.com/css2?family=Arimo&family=IBM+Plex+Sans:wght@400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --max-width: 1100px;
  --border-radius: 12px;
  /* --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace; */

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(
      rgba(1, 65, 255, 0.4),
      rgba(1, 65, 255, 0)
    );
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
/* 
html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: 'IBM Plex Sans Hebrew', sans-serif;
} */

body {
  color: rgb(var(--foreground-rgb));
  background: white !important;
  
}
/* .input-style {
  @apply rounded-[6px] bg-INPUT_BG font-spectral-regular;
}
.form-errors {
  @apply text-red-600 text-[10px] px-1 pt-1;
}
.tooltip {
  @apply bg-[#592E84] text-white z-10  max-w-[220px] rounded-sm px-1.5 py-1;
} */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}
textarea:focus,
input:focus {
  outline: none;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

/* PLACEHOLDER BELOW*/
.placeholder-grey::-webkit-input-placeholder{
  color:#52525B;
  opacity: 1;
}
.placeholder-grey:-moz-placeholder {
  color:#52525B
}
/* PLACEHOLDER ABOVE*/

/* React Quill Editor */


.QUILL_NON_CUSTOM .ql-editor p {
  font-size: 16px !important;
  text-transform: lowercase !important;
}

.QUILL_NON_CUSTOM .ql-editor h1 {
  font-size: 24px !important;
  font-weight: bold !important;
  /* color: red !important; */
}

.QUILL_NON_CUSTOM .ql-editor h3 {
  font-size: 20px !important;
  font-weight: 600 !important;
  /* color: green !important; */
}

.QUILL_NON_CUSTOM .ql-editor h4 {
  font-size: 18px !important;
  font-weight: 600 !important;
  /* color: blue !important; */
}
.QUILL_NON_CUSTOM .ql-toolbar.ql-snow {
  background-color: #ffffff !important;
  border: 1px solid white !important;
}
.QUILL_NON_CUSTOM .ql-toolbar {
  margin-top: -17px !important;
}
.QUILL_NON_CUSTOM .quill-container .ql-toolbar {
  position: sticky;
  top: 0;
  background: #F5F5F5;
  height: 42px;
  z-index: 1;
  padding-left: 80px;
}
@media screen and (max-width: 599px) {
  .QUILL_NON_CUSTOM .ql-formats{
    display:  none !important;
  }

}
.QUILL_NON_CUSTOM .ql-container.ql-snow {
  background-color: #ffffff !important;
  border: 1px solid white !important;
}
.QUILL_NON_CUSTOM .ql-toolbar.ql-snow {
  background-color: #ffffff !important;
  border: 1px solid white !important;
}
/* .QUILL_NON_CUSTOM .ql-editor h1::first-letter,
.QUILL_NON_CUSTOM .ql-editor h3::first-letter,
.QUILL_NON_CUSTOM .ql-editor h4::first-letter,
.QUILL_NON_CUSTOM .ql-editor p::first-letter {
  text-transform: uppercase !important;
} */

/* .ql-toolbar .ql-formats .ql-undo::before {
  content: "↺";
}

.ql-toolbar .ql-formats .ql-redo::before {
  content: "↻"; 
}*/

.QUILL_NON_CUSTOM .ql-container .ql-clipboard{
  display: none !important;
}