.placeholder-color {
  color: '#7C7C7C';
}
.auth-button {
  height: 50px;
  border-radius: 9px;
  padding: 10px;
  background-color: #592e84;
  color: white;
  border: unset;
  font-size: 16px;
}

.sign-in-button {
  height: 45px;
  width: 160px !important;
  border-radius: 9px;
  padding: 10px;
  background-color: #592e84;
  color: white;
  border: unset;
  font-size: 16px;
}

.box{
  width:95% !important;
}
.custom-row > * {
  padding-right: 0;
  padding-left: 0;
}
@media (max-width: 992px) {
  .box{
    width:100% !important;
  }
}