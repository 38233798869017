.header-style {
  width: 100vw;
  left: 0 !important;
  background-color: #F8F3FC;
  @media screen and (max-width:767px) {
    background-color:white;
  };
  
}
.bar-right {
  justify-content: space-between !important;
}
.bar-left {
  justify-content: flex-start !important;
}
