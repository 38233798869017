.ql-toolbar.ql-snow {
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 8px;
    height: 42px;
}
  @media screen and (max-width: 599px) {
    .ql-formats{
        display:  none !important;
      }
  
  }