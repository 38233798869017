.sidebar-left {
  left: 0;
}

.sidebar-right {
  right: 0;
}

.bar-left {
  position: fixed !important;
  left: 0 !important;
}
.bar-right {
  position: fixed !important;
  right: 0 !important;
  left: calc(100vw - 238px) !important;
  @media screen and (max-width:992px) {
    left: calc(100vw - 225px) !important;
  };

  
}
