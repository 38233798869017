//
// App Navbar
//


.app-navbar {
    margin-top: 15px;
    display: flex;
    align-items: end;
    gap: 30px;
    
    @media screen and (max-width:767px) {
        margin-top: 8px;
      };
    .app-navbar-item {
        display: flex;
        align-items: center;
    }

    .app-navbar-item-top {
        display: flex;
        align-items: center;
        @media screen and (max-width:767px) {
            display: none;
          };
    }

    &.app-navbar-stretch {
        .app-navbar-item {
            align-items: stretch;
        }
    }
}