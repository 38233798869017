@font-face {
    font-family: 'spectral-regular';
    src: local('font'), url('./fonts/Spectral-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'sans-regular';
    src: local('font'), url('./fonts/PlusJakartaSans-VariableFont_wght.ttf') format('truetype');
  }
  @font-face {
    font-family: 'inter-regular';
    src: local('font'), url('./fonts/Inter-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'inter-bold';
    src: local('font'), url('./fonts/Inter-SemiBold.ttf') format('truetype');
  }
